<template>
  <div class="content-rapport-th-new" ref="content">
    <div class="box-content">
      <div class="box-all-filter ">
        <filter-component
          label="Date début"
          classLabel="label-box-style w-78-px"
          v-if="checkPermission('A&VTHFDDDF')"
        >
          <template v-slot:body>
            <date-picker
              value-type="format"
              type="date"
              required
              :value="date_debut"
              @input="handleDateTimePicker($event, 'date_debut')"
              :disabled-date="disabledStartDate"
              input-class="custom-date-picker-filter"
              class="custom-date-picker-calender-filter  custom-date-picker-filter-white-back"
            ></date-picker
          ></template>
        </filter-component>
        <filter-component
          v-if="checkPermission('A&VTHFDDDF')"
          label="Date fin"
          classLabel="label-box-style w-78-px"
        >
          <template v-slot:body>
            <date-picker
              value-type="format"
              type="date"
              :value="date_fin"
              @input="handleDateTimePicker($event, 'date_fin')"
              :clear-button="true"
              :disabled-date="disabledEndDate"
              input-class="custom-date-picker-filter"
              class="custom-date-picker-calender-filter custom-date-picker-filter-white-back"
            ></date-picker> </template
        ></filter-component>
        <div class="box-label-champ mr-2" v-if="checkPermission('A&VTHFGP')">
          <div class="label-box-style  w-78-px">
            <span class="title-tabel">Groupe par </span>
          </div>
          <b-form-select
            class="b-form-select-new-style w-120-px "
            v-model="grouped"
            :options="[
              { key: null, value: 'Rien' },
              ...getFilterRapportThGruopes
            ]"
            text-field="value"
            value-field="key"
            @change="handleFilter(false, true, true)"
          ></b-form-select>
        </div>
        <input-file-excel-num-dossier
          v-show="checkPermission('A&VTHFPFXLS')"
          :value="numbers"
          @searchWithNumDossier="searchWithMultipleDossier"
        />
        <div v-if="getLoaderRapportTh" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <div class="notif-export-file-sci" v-if="getShowNotifExportFile">
            Préparation du fichier en cours
          </div>
          <SearchInput
            v-show="checkPermission('A&VTHRE')"
            :value="search"
            @changeSearchValue="changeSearchValueGlobale"
            className="search-white-color mr-1"
          />
          <Lock
            v-show="computedCheckAll && checkPermission('A&VTHVADD')"
            className="button-white-with-icon-color"
            :grouped="grouped"
          />
          <div class="profile-corner">
            <select-statue
              v-show="computedCheckAll && checkPermission('A&VTHMQS')"
              @refreshData="handleFilter(true, true)"
              className="button-white-with-icon-color"
              :grouped="grouped"
            />
          </div>
          <b-button
            size="sm"
            class="button-export-style  ml-2 mr-2 button-white-with-icon-color"
            title="Filter"
            id="popover-filter-rapport-th"
          >
            <font-awesome-icon icon="filter" />
          </b-button>
          <b-popover
            triggers="focus"
            target="popover-filter-rapport-th"
            placement="top"
            custom-class="my-custom-class-popover-filter"
          >
            <filter-component
              class="mb-2"
              label="Dépôts"
              classLabel="label-box-style w-93-px "
              v-if="checkPermission('A&VTHFD')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="depots"
                  :options="getFilterRapportThDepot"
                  :multiple="true"
                  class="customSelectMultipleFilter "
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderFilterRapportTh"
                  @input="handleFilter"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length }} dépôts</span
                    ></template
                  >
                </multiselect>
              </template></filter-component
            >
            <filter-component
              class="mb-2"
              label="Organismes"
              classLabel="label-box-style w-93-px"
              v-if="checkPermission('A&VTHFO')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="organismes"
                  :options="getFilterRapportThOrganisme"
                  :multiple="true"
                  class="customSelectMultipleFilter m"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderFilterRapportTh"
                  @input="handleFilter"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length }} organismes</span
                    ></template
                  >
                </multiselect>
              </template></filter-component
            >
            <filter-component
              class="mb-2"
              label="Statut"
              classLabel="label-box-style w-93-px"
              v-if="checkPermission('A&VTHFS')"
            >
              <template v-slot:body>
                <b-form-select
                  class="b-form-select-new-style  b-form-select-raduis-border "
                  v-model="statut"
                  :options="[
                    { value: '', key: null },
                    ...getFilterRapportThStatut
                  ]"
                  text-field="value"
                  value-field="key"
                  @change="handleFilter"
                ></b-form-select> </template
            ></filter-component>
            <filter-component
              class="mb-2"
              label="Verrouillé"
              classLabel="label-box-style w-93-px"
              v-if="checkPermission('A&VTHFVG')"
            >
              <template v-slot:body>
                <b-form-select
                  class="b-form-select-new-style b-form-select-raduis-border "
                  v-model="locked"
                  :options="[
                    { value: null, text: 'Tous' },
                    { value: 'non', text: 'Non' },
                    { value: 'oui', text: 'Oui' }
                  ]"
                  text-field="text"
                  value-field="value"
                  @change="handleFilter"
                ></b-form-select></template
            ></filter-component>
          </b-popover>
          <b-button
            v-show="checkPermission('A&VTHTX')"
            size="sm"
            title="Export"
            class="button-export-style mr-2 button-white-with-icon-color"
            @click="exportFiles"
            :disabled="getShowNotifExportFile"
          >
            <font-awesome-icon icon="cloud-download-alt"
          /></b-button>
          <b-button
            size="sm"
            title="Vider l'ordre"
            class="button-export-style mr-2 button-white-with-icon-color"
            @click="
              orderBy = null;
              order = null;
              handleFilter();
            "
          >
            <font-awesome-icon icon="retweet"
          /></b-button>
          <div class="box-label-champ" v-show="checkPermission('A&VTHFV')">
            <div
              class="label-box-style  label-box-style-w-1 ml-2 button-white-with-icon-color w-30-px"
            >
              <span class="title-tabel icon-color-cdg" title="Vue">
                <font-awesome-icon icon="table" />
              </span>
            </div>

            <b-form-select
              class="b-form-select-new-style h-31-px w-100-px"
              v-model="filterVueD"
              :options="getVueDynamiqueAV"
              text-field="name"
              value-field="id"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div class="body-box-rapport">
        <div class="tabs-menu-style">
          <img
            src="@/assets/Icon ionic-md-arrow-dropright-circle.png"
            class="flesh-icon"
            :class="{ 'flesh-icon-rigth': showTabs == false }"
            @click="showTabs = !showTabs"
          />
          <div
            v-for="item in computedTabsList"
            :key="item.title"
            @click="handleChangeTabs(item)"
          >
            <div
              class="box-tabs"
              :class="{ 'Active-tabs-style': selectedTabs == item.key }"
            >
              <div class="icon">
                <img :src="item.img" />
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
            <hr class="ligne" />
          </div>
        </div>

        <div
          class="tabs-body-style"
          :class="{
            'display-tabs': showTabs == false
          }"
        >
          <div class="tab-item-style" v-show="selectedTabs == 'Statistique'">
            <div class="title-tab-item">Statistique</div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <Charts
                :label="[
                  'Montant ADP',
                  'Montant facture à editer',
                  'Montant payé'
                ]"
                :data="computedGetDataStatisticChartRapportTh"
                :datasets="datasetsSoldeChart"
                :width="217"
                :height="170"
                :customLabelClass="true"
                class="display"
              />
              <div class="surface-box mt-1">
                <div class="valueBoxStatue">
                  <span class="label-blue"> MONTANT FINAL :</span>
                  <span v-if="getDataStatisticRapportTh">
                    {{ getDataStatisticRapportTh.total_montant_final }}</span
                  >
                </div>
              </div>
              <div class="surface-box mt-1">
                <div class="valueBoxStatue">
                  <span class="label-green"> PAYÉ :</span>
                  <span v-if="getDataStatisticRapportTh">
                    {{ getDataStatisticRapportTh.paye }}</span
                  >
                </div>
              </div>
              <div class="surface-box mt-1">
                <div class="valueBoxStatue">
                  <span class="label-red"> ADP :</span>
                  <span v-if="getDataStatisticRapportTh">
                    {{ getDataStatisticRapportTh.ADP }}</span
                  >
                </div>
              </div>
              <div class="surface-box mt-1">
                <div class="valueBoxStatue">
                  <span class="label-blue"> FACTURE A EDITER :</span>
                  <span v-if="getDataStatisticRapportTh">
                    {{ getDataStatisticRapportTh.facture_edite }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="table-rapport-style"
          :class="{ 'width-table-rapport': showTabs == false }"
        >
          <b-table
            v-show="getTotalRapportTh && !getErrorRapportTh"
            show-empty
            id="my-table"
            class="custom-table-style tableRapportThNews table-header"
            :items="computedRows"
            :fields="ComputedgetAllHeader"
            bordered
            sticky-header
            hover
            responsive
            head-variant="light"
            tbody-tr-class="ligneNormale"
            empty-text="Il n'y a aucun enregistrement à afficher"
          >
            <template v-slot:head(check_all)>
              <b-form-checkbox
                v-model="checkAll"
                @change="checkAllFunction"
                class="check-th"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </template>
            <template v-slot:head()="data">
              <template v-if="data.field.key != 'show'">
                {{ data.field.label }}
                <span class="d-inline-block">
                  <font-awesome-icon
                    @click="handleClickOrderData(data.field.key, 'ASC')"
                    icon="arrow-up"
                    :class="
                      data.field.key === orderBy && order === 'ASC'
                        ? 'style-arrow-selected'
                        : 'style-arrow-not-selected'
                    "/>

                  <font-awesome-icon
                    @click="handleClickOrderData(data.field.key, 'DESC')"
                    icon="arrow-down"
                    :class="
                      data.field.key === orderBy && order === 'DESC'
                        ? 'style-arrow-selected'
                        : 'style-arrow-not-selected'
                    "/></span
              ></template>
            </template>
            <template v-slot:cell()="data">
              <div v-if="grouped">-</div>
              <div v-else>
                <span
                  v-if="
                    getFieldPropertyRapportTh(data.field.key) &&
                      getFieldPropertyRapportTh(data.field.key).editable
                  "
                >
                  <EditableInput
                    :editable="
                      checkPermission('A&VTHMD') && !data.item.montant_min_lock
                    "
                    :champName="data.field.key"
                    :editableType="
                      getFieldPropertyRapportTh(data.field.key).editableType
                    "
                    :optionsSelect="
                      getFieldPropertyRapportTh(data.field.key).optionsSelect
                    "
                    :item="data.item"
                    :value="data.item[data.field.key]"
                    :type="getFieldPropertyRapportTh(data.field.key).type"
                    :updateFunction="updateDataInProjetTh"
                    :withFixe="true"
                    :class="{
                      'commente-class': data.field.key == 'commentaire'
                    }"
                    :title="data.item[data.field.key]"
                    label="full_name"
                  />
                </span>
                <div v-else>{{ data.item[data.field.key] }}</div>
              </div>
            </template>
            <template v-slot:cell(numero_dossier)="data">
              <div v-if="grouped">{{ data.item.name }}</div>
              <div class="d-flex" v-else>
                <font-awesome-icon
                  icon="lock-open"
                  class="ml-1"
                  v-if="data.item.montant_min_lock == false"
                />
                <font-awesome-icon
                  icon="lock"
                  class="ml-1"
                  v-if="data.item.montant_min_lock == true"
                />
                <font-awesome-icon
                  :title="data.item.statue_paye_non_paye"
                  icon="circle"
                  :class="{
                    'red-color-icon': data.item.statue_paye_non_paye != 'PAYE',
                    'green-color-icon': data.item.statue_paye_non_paye == 'PAYE'
                  }"
                />
                <span> {{ data.item.numero_dossier }}</span>
              </div>
            </template>
            <template v-slot:cell(check_all)="data">
              <b-form-checkbox
                v-model="data.item.check"
                class="check-th"
                @change="checkAllVerif(data.item.check, data.index)"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </template>
            <template v-slot:cell(show)="data" v-if="grouped">
              <button class="btn btn-anomalie" @click="data.toggleDetails()">
                <font-awesome-icon
                  class="icon-plus"
                  icon="plus-square"
                  v-if="data.detailsShowing == false"
                  title="Afficher les dossiers"
                />
                <font-awesome-icon
                  class="icon-plus"
                  icon="minus-square"
                  v-if="data.detailsShowing == true"
                  title="Masques les dossiers"
                />
              </button>
            </template>
            <template #row-details="data">
              <span
                v-for="(item, index) in data.item.projects"
                :key="data.item.name + index"
                ><template>
                  <span class="align-td-item border-botom background-week">
                    <td
                      class="item-details-style"
                      v-for="(col, i) in ComputedgetAllHeader"
                      :key="'column' + i + 'p' + index"
                      :class="{
                        'col-check-obligee': col.key == 'check_all',
                        ' col-plus-obligee': col.key == 'show',
                        ' num-lot-td ': col.key == 'numero_dossier' && grouped,
                        col_stiky_num_rapport_th_new:
                          col.key == 'numero_dossier' && !grouped
                      }"
                    >
                      <div
                        class="check-th custom-control custom-checkboxc cpl-7"
                        v-if="col.key == 'check_all'"
                      >
                        <b-form-checkbox
                          v-model="item.check"
                          @change="checkAllVerifDetails(data.item, item)"
                          class="check-th-details ml-2"
                          :value="true"
                          :unchecked-value="false"
                        >
                        </b-form-checkbox>
                      </div>
                      <div v-else-if="col.key == 'show'" class="cp-show"></div>
                      <div v-else-if="col.key == 'numero_dossier'">
                        <div class="d-flex">
                          <font-awesome-icon
                            icon="lock-open"
                            class="ml-1"
                            v-if="item.montant_min_lock == false"
                          />
                          <font-awesome-icon
                            icon="lock"
                            class="ml-1"
                            v-if="item.montant_min_lock == true"
                          />
                          <font-awesome-icon
                            :title="item.statue_paye_non_paye"
                            icon="circle"
                            :class="{
                              'red-color-icon':
                                item.statue_paye_non_paye != 'PAYE',
                              'green-color-icon':
                                item.statue_paye_non_paye == 'PAYE'
                            }"
                          />
                          <span> {{ item.numero_dossier }}</span>
                        </div>
                      </div>
                      <div
                        class="vertical-align"
                        v-else-if="
                          getFieldPropertyRapportTh(col.key) &&
                            getFieldPropertyRapportTh(col.key).editable
                        "
                      >
                        <EditableInput
                          :editable="
                            checkPermission('A&VTHMD') &&
                              !data.item.montant_min_lock
                          "
                          :champName="col.key"
                          :editableType="
                            getFieldPropertyRapportTh(col.key).editableType
                          "
                          :optionsSelect="
                            getFieldPropertyRapportTh(col.key).optionsSelect
                          "
                          :item="item"
                          :value="item[col.key]"
                          :type="getFieldPropertyRapportTh(col.key).type"
                          :updateFunction="updateDataInProjetTh"
                          :withFixe="true"
                        />
                      </div>
                      <div v-else>{{ item[col.key] }}</div>
                    </td>
                  </span></template
                ></span
              >
            </template>
          </b-table>
          <div
            v-if="this.getTotalRapportTh && !getErrorRapportTh"
            class=" footer-style"
          >
            <b-pagination
              v-model="page"
              :total-rows="getTotalRapportTh"
              :per-page="per_page"
              aria-controls="my-table"
              pills
              align="center"
              size="sm"
              @change="pagination"
              class="pagination-style"
            ></b-pagination>
            <div class="per-page-element-style">
              <div class="box-label-champ">
                <div class="label-box-style">
                  <span class="title-tabel">Eléments par page</span>
                </div>
              </div>
              <b-form-select
                v-model="per_page"
                :options="computedperPageList"
                @change="changePerPage"
                class="b-form-select-new-style bg-select"
              ></b-form-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import FilterComponent from '@/views/ThProject/frais/component/filterComponent.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      orderBy: null,
      order: null,
      checkAll: false,
      grouped: null,
      date_fin: null,
      date_debut: null,
      statut: null,
      numbers: null,
      search: null,
      filterVueD: null,
      locked: null,
      showTabs: true,
      selectedTabs: 0,
      zoom: false,
      depots: [],
      organismes: [],
      page: 1,
      per_page: 100,
      TabsList: [
        {
          title: 'Statistique',
          img: 'image/Icon-ionic-ios-pie.png',
          key: 0
        }
      ],
      perPageList: [
        { value: 100, text: 100 },
        { value: 200, text: 200 },
        { value: 300, text: 300 }
      ],
      perPageListGrouped: [
        { value: 10, text: 10 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
      fields: [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee'
        },
        {
          key: 'show',
          label: '',
          thClass: 'th-plus-obligee',
          tdClass: 'col-plus-obligee'
        }
      ],
      datasetsSoldeChart: {
        position: 'bottom',
        title: '',
        backgroundColor: ['#ff0000ba', '#0d09b08a', '#0baa0b7d']
      }
    };
  },
  computed: {
    ...mapGetters([
      'getShowNotifExportFile',
      'checkPermission',
      'getDataRapportTh',
      'getVueDynamiqueAV',
      'getFilterRapportThGruopes',
      'getFilterRapportThDepot',
      'getFilterRapportThOrganisme',
      'getFilterRapportThStatut',
      'getDataStatisticRapportTh',
      'getDataStatisticChartRapportTh',
      'getTotalRapportTh',
      'getErrorRapportTh',
      'getFieldPropertyRapportTh',
      'getLoaderFilterRapportTh',
      'getLoaderRapportTh',
      'getLoaderExportDataRapportTh'
    ]),
    computedperPageList() {
      return this.grouped ? this.perPageListGrouped : this.perPageList;
    },
    computedGetDataStatisticChartRapportTh() {
      return Object.values(this.getDataStatisticChartRapportTh);
    },
    computedRows() {
      if (!this.getDataRapportTh) {
        return [];
      }
      if (this.type === 'valides') {
        return this.getDataRapportTh.filter(
          row => !row.anomalies || row.anomalies.length == 0
        );
      } else if (this.type === 'invalides') {
        return this.getDataRapportTh.filter(
          row => row.anomalies && row.anomalies.length > 0
        );
      }

      return this.getDataRapportTh;
    },
    computedCheckAll() {
      var table = [];
      if (!this.getLoaderRapportTh) {
        table =
          !this.grouped &&
          this.computedRows.length &&
          !this.computedRows[0].projects
            ? this.computedRows.filter(item => item.check == true)
            : this.computedRows.length && this.computedRows[0].projects
            ? this.computedRows
                .map(item => {
                  return item.projects.filter(i => i.check == true);
                })
                .flat()
            : [];
      }

      return table.length ? true : false;
    },
    ComputedgetAllHeader() {
      let field = this.grouped
        ? this.fields
        : this.fields.filter(item => item.key != 'show');
      if (this.filterVueD != null) {
        let coloms = [];
        coloms = this.getVueDynamiqueAV.find(item => item.id == this.filterVueD)
          ?.columns;
        field = [...field, ...coloms];
      }

      let indexOfNum = field.findIndex(i => i.key == 'numero_dossier');
      if (indexOfNum >= 0) {
        if (this.grouped) {
          field[indexOfNum].thClass = 'num-lot-th';
          field[indexOfNum].tdClass = 'num-lot-td';
        } else {
          field[indexOfNum].thClass = 'th_stiky_num_rapport_th_new';
          field[indexOfNum].tdClass = 'col_stiky_num_rapport_th_new';
        }
      }
      return field;
    },
    computedTabsList() {
      let list = this.TabsList;
      return list;
    }
  },
  methods: {
    ...mapActions([
      'exportFileNewRapportTh',
      'fetchDataForFilterRapportTh',
      'fetchDataForRapportTh',
      'getAllVueDynamiqueAV',
      'fetchStatisticDataForRapportTh',
      'updateDataInProjetTh',
      'getSettingOrgenismeTh',
      'getSettingFilialeTh',
      'fetchAllRegieForAdmin',
      'fetchEmployeParTypeTh',
      'getAllProFilial'
    ]),
    handleClickOrderData(orderBy, order) {
      this.orderBy = orderBy;
      this.order = order;
      this.handleFilter();
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == true && this.checkAll == false) {
        this.checkAll = true;
      }
    },
    checkAllVerif(item, i) {
      if (item == false && this.checkAll == true) {
        this.checkAll = false;
      }
      if (this.grouped) {
        item.projects.map(i => (i.check = item.check));
      }
    },
    checkAllFunction() {
      if (this.grouped) {
        this.computedRows.map(item => {
          item.check = this.checkAll;
          item.projects.map(project => {
            project.check = this.checkAll;
          });
        });
      } else {
        this.computedRows.map(item => {
          item.check = this.checkAll;
        });
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.anomalies) return 'ligneAnomalie';
      else return 'ligneAlert';
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    handleFilter(refreshStatistic, checkReset, resetPerPage, refreshPaginate) {
      if (resetPerPage) {
        this.per_page = this.grouped ? 10 : 100;
      }
      if (!refreshPaginate) {
        this.page = 1;
      }
      this.fetchDataForRapportTh({
        grouped: this.grouped,
        date_fin: this.date_fin,
        date_debut: this.date_debut,
        orderBy: this.orderBy,
        order: this.order,
        statut: this.statut,
        numbers: this.numbers,
        search: this.search,
        locked: this.locked,
        depots: this.depots,
        organismes: this.organismes,
        page: this.page,
        per_page: this.per_page
      });
      if (refreshStatistic) {
        this.fetchStatisticDataForRapportTh({
          grouped: this.grouped,
          date_fin: this.date_fin,
          date_debut: this.date_debut,
          statut: this.statut,
          numbers: this.numbers,
          search: this.search,
          locked: this.locked,
          depots: this.depots,
          organismes: this.organismes
        });
      }
      if (checkReset) {
        this.checkAll = false;
      }
    },
    getAllDataForFilterDynamicAction() {
      this.fetchDataForFilterRapportTh({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.getAllDataForFilterDynamicAction();
      this.handleFilter(true);
    },
    searchWithMultipleDossier(value) {
      if (this.numbers != value) {
        this.numbers = value;
        this.handleFilter();
      }
    },
    async changeSearchValueGlobale(e) {
      this.search = e;
      this.handleFilter();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter(false, false, false, true);
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    async exportFiles() {
      const response = await this.exportFileNewRapportTh({
        date_fin: this.date_fin,
        date_debut: this.date_debut,
        statut: this.statut,
        numbers: this.numbers,
        search: this.search,
        locked: this.locked,
        depots: this.depots,
        organismes: this.organismes,
        view: this.filterVueD,
        ids: this.computedRows
          .filter(item => item.check == true)
          .map(item => item.id)
      });
      if (response.success) {
        this.$alert('', response.msg, 'success');
      } else {
        this.$alert('', 'Une erreur est survenue', 'danger');
      }
    },
    handleChangeTabs(tab) {
      this.selectedTabs = tab.key;
    }
  },
  async mounted() {
    this.getAllProFilial();
    this.getAllDataForFilterDynamicAction();
    this.handleFilter(true);
    const responseFields = await this.getAllVueDynamiqueAV({ blocked: false });
    if (responseFields && this.getVueDynamiqueAV.length) {
      this.filterVueD = this.getVueDynamiqueAV[0].id;
    }
    this.getSettingOrgenismeTh();
    this.getSettingFilialeTh({
      type: null,
      type_societe: null,
      search: null
    });
    this.fetchAllRegieForAdmin();
    this.fetchEmployeParTypeTh();
  },
  components: {
    FilterComponent,
    Lock: () => import('../componentRapportTh/Lock.vue'),
    inputFileExcelNumDossier: () =>
      import('../componentRapportTh/inputFileExcelNumDossier.vue'),
    selectStatue: () => import('../componentRapportTh/selectStatue.vue'),
    SearchInput: () => import('../../component/SearchInput.vue'),
    Charts: () => import('@/views/component/customChart.vue'),
    EditableInput: () => import('@/views/component/EditableInput.vue')
  }
};
</script>

<style lang="scss" scoped>
.content-rapport-th-new {
  .chargement {
    margin-left: 10px;
    font-size: 8px;
    .spinner-border {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .body-box-rapport {
    .tabs-body-style {
      .tab-item-style {
        display: inline !important;
        .title-tab-item {
          font-family: 'Montserrat', sans-serif;
          font-size: 15px;
          font-weight: 500;
          color: #5d5d5d;
        }
        .ligne {
          width: 100%;
          margin: auto;
          margin-top: 7px;
          margin-bottom: 7px;
        }
        .body-tab-item {
          .badge-style {
            min-width: 51px;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
            background-color: #6866b7 !important;
            color: #fff !important;
            border: 1px solid #6866b7;
            border-radius: 19px;
          }
        }
      }
    }
    .surface-box {
      .title {
        color: #5d5d5d;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 500;
        text-align: left;
        margin-left: 19%;
        margin-bottom: 2px;
      }
      .valueBox {
        text-align: center;
        margin: auto;
        border-radius: 10px;
        background-color: #f5f5ff;
        width: 200px;
        padding: 10px;
        font-size: 17px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        color: #ff7f8b;
      }
      .valueBoxStatue {
        // text-align: center;
        margin: auto;
        border-radius: 10px;
        background-color: #f5f5ff;
        width: 200px;
        padding: 5px 10px;
        font-size: 11px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        color: #5d5d5d;
        display: flex;
        justify-content: space-between;
        // div {
        //   width: 50%;
        .label-green {
          color: #13d613 !important;
        }
        .label-red {
          color: red !important;
        }
        .label-blue {
          color: #3f47aa !important;
        }
        // }
      }
    }
    .display-tabs {
      display: none;
    }
    .table-rapport-style {
      .table {
        height: calc(100% - 50px);
      }
      .b-table-sticky-header {
        max-height: calc(100vh - 175px) !important;
        height: calc(100vh - 175px);
        padding-bottom: 0px;
        margin-bottom: 0px;
      }
    }
    .width-table-rapport {
      width: 100%;
    }
  }
}
.custom-spinner-export {
  height: 15px;
  width: 15px;
  position: absolute;
  background: #ffffffa1;
}
</style>
<style lang="scss">
.commente-class {
  .show-value {
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.table-rapport-style .tableRapportThNews {
  .custom-checkbox {
    margin-right: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  td {
    width: 125px;
    min-width: 125px;
    line-height: 14px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 125px;
    min-width: 125px;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    // color: #fff;
    vertical-align: middle;
    td {
      width: 125px;
      min-width: 125px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}
.col_stiky_num_rapport_th_new {
  position: sticky;
  left: 30px;
  width: 150px !important;
  min-width: 150px !important;
  text-align: start;
}
.th_stiky_num_rapport_th_new {
  position: sticky;
  left: 30px;
  z-index: 20 !important;
  width: 150px !important;
  min-width: 150px !important;
}
.cpl-7 {
  padding-left: 7px;
}
.cp-show {
  padding: 0px 21px;
}
.style-arrow-not-selected {
  color: #aaacb0;
}
.style-arrow-selected {
  color: #4d4bac;
}
.h-31-px {
  height: 31px;
}
</style>
